function Subheader(param: {title: string, subtitle: string}) {
  return (
    <div className="bg-blue-600 h-screen max-h-max max-lg:px-2 pb-20" style={{height: 'calc(100%)', backgroundImage: "url(gradient.svg)", backgroundSize: "cover"}}>
        <h1 className="text-5xl text-white font-bold pt-40 -mt-[90px] font-tt max-md:text-3xl md:w-[70%] md:ml-[15%]">
          {param.title}
        </h1>
        <p className="text-l text-slate-200 px-2 max-sm:w-10/12 max-sm:mx-auto mt-3">
          {param.subtitle}
       </p>
    </div>
  );
}

export default Subheader;