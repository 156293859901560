import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { toast } from 'react-toastify';
import HeroCarousel from '../../components/HeroCarousel';
import Button from "../../components/Button";
import {useNavigate} from "react-router";
import ReactGA from "react-ga";
import React from "react";
import {T, useTranslate} from '@tolgee/react';

function HeroSection() {
  const { trackEvent } = useMatomo();

  // TODO: Load this from a CMS
  const originLogos = [ "DEMO/logos/madein.png", "DEMO/logos/gdpr.png"];
  const videoComingSoon = () => {
    trackEvent({ category: 'hero section', action: 'video-available-soon' });
    ReactGA.event({
      category: 'hero section',
      action: `video-available-soon`,
      label: "Video Comming soon",
      nonInteraction: true
    });
    toast.info('The video of our product will be available soon.', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
    theme: "light",
    });}


  const goToSignUp = () => {
    ReactGA.event({
      category: 'hero section',
      action: `go-to-sign-up`,
      label: "Sign Up - Hero",
      nonInteraction: true
    });
    window.open('https://app.eu.3folio.finance/register', '_blank');
  }
  const t = useTranslate();

  return (
    <div className=" -mt-10 max-lg:px-2 h-full max-h-content" >
        <div className="max-w-screen-xl mx-auto px-10 max-lg:px-2 flex h-full items-center pt-36 md:pb-18 pb-6 max-lg:flex-col max-md:pb-18">
          <div className={ "lg:w-1/2 w-full" }>
            <h1 className="mb-2 text-[36px] text-[#2563EB] max-sm:text-[24px] max-sm:tracking-[1px] tracking-[3.6px] font-extrabold md:-mt-[200px] -mt-[100px] text-left pt-36  max-md:text-[36px] max-md:text-center max-md:pt-12" >
              <T keyName="header_enterprise" /><br /> {' '}<T keyName={"header_crypto"} />
              {/*Enterprise<br/> Crypto Co-Pilot*/}
            </h1>
            <HeroCarousel/>
            <p className="text-xl text-medium text-[#6F6C90] max-sm:w-10/12 max-sm:mx-auto text-left max-md:text-center">
              <T keyName="hero_desc"/>
            </p>
            <Button text={<T keyName="register_whitelist" />}  color="primary" size="large" className="mt-[40px] py-3 block mx-0 w-4/10 min-w-[0px] max-md:mx-auto" onClick={() => goToSignUp() }/>
            <div className="inline-block md:justify-left justify-center w-full md:text-left text-center border-t pt-3 mt-2 mt-8">
              { originLogos.map((logo, i) => (
                  <img src={logo} alt="logo" className="inline grayscale w-auto max-h-[60px] mt-3 mr-6 ml-0" key={"originLogo-" + i} />
              ))}
            </div>
          </div>
          <div className="relative lg:w-1/2 w-full hidden md:block">
            <img src="./hero-image.svg" className="display-block m-auto w-[100%] pl-10 max-md:w-full max-md:px-10" alt="Product illustration"/>
            {/*<img src="VideoButton.svg" className="absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-1/2 cursor-pointer hover:scale-105 active:scale-95 transition-transform" alt="Video button" onClick={videoComingSoon}/>*/}

          </div>
        </div>
    </div>
  );
}

export default HeroSection;