import React from "react";
import {CheckIconCircle} from "../../components/icons/CheckIconCircle";

export const TrackingDescList = () => {

    return (
        <div className={'flex justify-start flex-col'}>
             <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>1 Workspace included</span>
            </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>max. 10 sources</span>
            </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>max. 5 users</span>
            </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Net asset value history</span>
            </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Portfolio tracking with bank, DEFI and NFT support</span>
            </div>
        </div>
    )
}