import { T } from '@tolgee/react';
import { motion } from 'framer-motion';
import React, { useState, useEffect, MouseEventHandler } from 'react';
import Button from './Button';
import {CloseIcon} from "./icons/CloseIcon";
import {CloseModalIcon} from "./icons/CloseModalIcon";

interface ModalProps {
  isOpen: boolean;
  closeModal: MouseEventHandler<HTMLElement>;
}

const Modal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
  if (!isOpen) return null;

  const onButtonClick = (url: string) => {
    window.open(url, '_blank');
  };



  return (
    <motion.div className="fixed inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[99998]" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <motion.div className="bg-white p-8 rounded-md relative z-[99999]" initial={{ scale: 0.5 }} animate={{ scale: 1 }} exit={{ scale: 0.5 }}>
        <div className="bg-white p-8 rounded-md relative max-w-md mx-auto">
          <h2 className="text-xl font-semibold mb-6 mt-6 text-center">
            <T keyName={'select_contact'} />
          </h2>
          <div className="flex justify-around">
            <div className="flex flex-col items-center">
              <img src="/DEMO/images/michael.png" alt="CEO" className="w-[75px] h-[75px] box m-auto border-[5px] border-blue-100 rounded-full"/>
              <h3 className="text-lg font-medium mt-2">Michael Schöngruber</h3>
              <p className="text-gray-600">CEO</p>
              <Button text={<T keyName={'select'} />} color="primary" size="medium" className='mt-2' onClick={() => onButtonClick('https://calendly.com/michael-3folio/meeting-15-min?month=2023-03')}/>
            </div>
          </div>
          <button onClick={closeModal} className="absolute top-0 right-1 text-gray-700 hover:text-gray-900">
           <span className=" items-center justify-center flex w-[32px] h-[32px] rounded-full">
                <CloseModalIcon />
            </span>
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

const CalendlyModalComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const openModalListener = () => {
      setIsOpen(true);
    };

    const closeModalListener = () => {
      setIsOpen(false);
    };

    window.addEventListener('openCalendlyModal', openModalListener);
    window.addEventListener('closeCalendlyModal', closeModalListener);

    return () => {
      window.removeEventListener('openCalendlyModal', openModalListener);
      window.removeEventListener('closeCalendlyModal', closeModalListener);
    };
  }, []);

  return <Modal key={Math.random()} isOpen={isOpen} closeModal={() => setIsOpen(false)} />;
};

export default CalendlyModalComponent;
