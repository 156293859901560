import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import {ToggleWithText} from "../../components/ToggleWithText";
import {ListItem} from "../../components/ListItem";
import {assetOptions, coreFunctions, functionsAccounting, functionsReporting, functionsTracking, importOptions, payPerUse} from "../../utils";
import {PriceListCard} from "../../components/PriceListCard";

import {TrackingDescList} from "./TrackingDescList";
import {CoPilotList} from "./CoPilotList";
import {EnterpriceSuiteList} from "./EnterpriceSuiteList";
import {InfoCircleIcon} from "../../components/icons/InfoCircleIcon";
import { T } from '@tolgee/react';
import {PricePlanCalculationModal} from "./PricePlanCalculationModal";


declare global {
    interface Window {
        Rewardful: {
            referral: string | null;
            ready(callback: () => void): void;
        };
    }
}

export const PriceList = () => {

    const [isMonthly, setIsMonthly] = useState(true)
    const [referralId, setReferralId] = useState('')

    const handleFormData = (
        event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        setIsMonthly(!isMonthly)
    }

    useEffect(() => {
        // Ensure that the Rewardful object is available in the global scope
        if (window.Rewardful) {
            window.Rewardful.ready(() => {
                if (window.Rewardful.referral) {
                    console.log('Current referral ID: ', window.Rewardful.referral);
                    setReferralId(window.Rewardful.referral)
                } else {

                }
            });
        } else {
            console.log('Rewardful not initialized.');
        }
    }, []);




    return (
        <>
            <Header />

                <div className="mx-auto max-w-screen-xl container w-full px-2 flex justify-center flex-col xl max-h-max max-lg:px-2 md:pb-20 pb-6 ">
                    <h1 className="text-5xl flex md:flex-row flex-col flex justify-center text-center text-[#0A1B39] text-[24px] font-bold pt-40 -mt-[90px] font-tt max-md:text-3xl md:w-[70%] md:ml-[15%]">
                        <T keyName={'pricing_plan_title'} /> <span className={'pl-1.5 text-[#317BFF]'}><T keyName={'pricing_plan_title_blue'} /></span>
                    </h1>
                    <div className={'text-center relative justify-center w-full'}>
                        <div className={"flex justify-center "}>
                            <p className="md:flex justify-center text-l text-center text-[18px] font-medium text-[#454545] px-2 max-sm:w-10/12 max-sm:mx-auto mt-3">
                                <T keyName={'pricing_plan_subtitle'} />
                            </p>
                        </div>
                        <div className={'inline-block lg:absolute right-0 -top-2 pt-3 text-center justify-end self-end'}>
                            <ToggleWithText
                                firstOption="Monthly"
                                secondOption="Annually"
                                checked={isMonthly}
                                id="paymentInterval"
                                name="paymentInterval"
                                onChange={handleFormData}
                            />
                        </div>
                    </div>
                </div>


                <div className="mx-auto flex max-w-screen-xl container w-full flex justify-center flex-row xl  max-h-max max-lg:px-2 pb-20">
                    <div className={'w-1/4 hidden md:flex flex-col mr-3 static min-w-[307px]'}>
                        <div className={'flex flex-col mt-12'}>
                            <h3 className={'text-[#373A45] font-bold pb-2'}>Featured Status</h3>
                            <ListItem key={1} color={"#1AB200"} text={'Ready to use'}></ListItem>
                            <ListItem key={2} color={"#F76527"} text={'In Progress'}></ListItem>
                            <ListItem key={3} color={"#686F87"} text={'Coming 2024'}></ListItem>
                        </div>

                        <div className={' mt-[76px] pb-[30px] text-[16px] font-medium text-[#83899F]'}>
                        <span className={'text-accent'}> Please note! </span>
                        All our fees and prices are before VAT.<br/>
                        </div>

                        <div className={'flex flex-col mt-[101.5px]'}>
                            <>
                            <h3 className={'text-[#373A45] font-bold'}>Core Functions</h3>
                            {coreFunctions.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`core-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                            <span className={'font-bold pt-3 text-xs'}>*per tax year, **for supported countries</span>
                            <h3 className={'text-[#373A45] font-bold mt-[55px]'}>Import Options</h3>
                            {importOptions.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`import-options-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                             <h3 className={'text-[#373A45] font-bold mt-[55px]'}>Supported types of Asset</h3>
                            {assetOptions.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`import-options-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                            <h3 className={'text-[#373A45] font-bold mt-[55px]'}>Tracking Module</h3>
                            {functionsTracking.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`funcTracking-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                            <h3 className={'text-[#373A45] font-bold mt-[55px]'}>Wealth Manager Module</h3>
                            {payPerUse.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`funcReporting-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                            <h3 className={'text-[#373A45] font-bold mt-[55px]'}>Accounting Module</h3>
                            {functionsAccounting.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`funcAccounting-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                            <span className={'font-bold pt-3 text-xs'}>*for supported countries</span>
                            <h3 className={'text-[#373A45] font-bold mt-[55px]'}>Reporting Module</h3>
                            {functionsReporting.map((item, index) =>
                                <ListItem border tooltip={item.tooltip} key={`funcReporting-${index}`} small color={item.color} text={item.title}></ListItem>
                            )}
                          
                            </>

                        </div>
                    </div>

                    <div className={'md:w-3/4 w-full flex md:flex-row flex-col overflow-x-auto'}>

                        <div className={'w-full md:w-1/4 min-w-[230px]'}>
                            <PriceListCard
                                desc={<TrackingDescList />}
                                type={'Copilot'}
                                btnText={"Start now"}
                                pricePlanId={isMonthly ? 'copilot_mon' : 'copilot_anu'}
                                isMonthly={isMonthly}
                                referralId={referralId}
                                individual
                                free
                            />
                        </div>
                        <div className={'w-full md:w-1/4 min-w-[230px]'}>
                            <PriceListCard
                                desc={<CoPilotList />}
                                type={'Copilot Pro'}
                                price={`${isMonthly ? '€45' : '€495'}`}
                                prevPrice={`${isMonthly ? '€99' : '€1189'}`}
                                trial={<div className={'flex-row flex'}><InfoCircleIcon /><p className={`flex justify-start ${isMonthly ? 'flex-row' : 'flex-col'}`}><span className={`flex pl-1 text-[#485066] text-[11px] font-[500]`}>{isMonthly ? '1 month' : '12 months'}</span><span className={'pl-1 flex text-[#83899F] text-[11px] font-[500]'}>contract commitment</span></p></div>}
                                btnText={"Start 14 day trial"}
                                pricePlanId={isMonthly ? 'copilot-pro_mon' : 'copilot-pro_anu'}
                                isMonthly={isMonthly}
                                referralId={referralId}
                                individual
                            />
                        </div>
                        <div className={'w-full md:w-2/4 min-w-[230px]'}>
                            <PriceListCard
                                type={'Enterprise Suite'}
                                desc={<EnterpriceSuiteList />}
                                btnText={"Contact us"}
                                isMonthly={isMonthly}
                                pricePlanId={isMonthly ? 'enterprise_mon' : 'enterprise_anu'}
                                referralId={referralId}
                                trial={<div className={'flex justify-center'}><InfoCircleIcon /><span className={`pl-1 text-[#485066] text-[11px] font-[500]`}>12 months</span><span className={'pl-1 text-[#83899F] text-[11px] font-[500]'}>contract commitment</span></div>}
                                enterprise
                                textDesc
                            />
                        </div>
                    </div>
                </div>



            <Footer />
        </>
    )
}