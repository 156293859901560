import React from 'react'

type Props = {
    firstOption: string
    secondOption: string
    checked: boolean
    id: string
    name: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    small?: boolean
    disabled?: boolean
}

export const ToggleWithText = ({
                                   firstOption,
                                   secondOption,
                                   checked,
                                   id,
                                   name,
                                   onChange,
                                   small = false,
                                   disabled = false,
                               }: Props) => {
    return (
        <label
            htmlFor={id}
            className={`inline-flex items-center self-end  p-1 rounded-full ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            } text-gray-700 border text-sm`}
        >
            <input
                id={id}
                name={name}
                type="checkbox"
                className="hidden peer"
                onChange={onChange}
                disabled={disabled}
            />
            <span className={`px-2.5 text-[14px] py-2 rounded-full ${checked ? 'bg-accent text-white' : 'bg-white text-gray-700'} text-xs `}>
				{firstOption}
			</span>
            <span className={`px-2.5 text-[14px] py-2 rounded-full ${!checked ? 'bg-accent text-white' : 'bg-white text-gray-700'} text-xs `}>
				{secondOption}
			</span>

        </label>
    )
}
