import React, {isValidElement, useState} from "react";
import Button from "./Button";
import {cardsData} from "../utils";
import {CheckIcon} from "./icons/CheckIcon";
import {GiftIcon} from "./icons/GiftIcon";
import {RocketIcon} from "./icons/RocketIcon";
import {EnterpriseIcon} from "./EnterpriseIcon";
import {UserIcon} from "./icons/UserIcon";
import {PricePlanCalculationModal} from "../sections/price-list/PricePlanCalculationModal";


type Props = {
    type?: string
    price?: string;
    prevPrice?: string;
    trial?: string | JSX.Element
    icon?: JSX.Element | string
    desc?: string | JSX.Element
    gift?: boolean
    individual?: boolean
    commingSoon?: boolean
    enterprise?: boolean
    btnText?: string
    pricePlanId?: string
    isMonthly?: boolean
    referralId?: string
    free?: boolean
    textDesc?: boolean
}




export const PriceListCard = ({type, price, prevPrice, trial = '',isMonthly, referralId,textDesc, free, desc, gift,individual, enterprise, commingSoon, btnText = "Choose Plan", pricePlanId}: Props) => {


    const queryParameters = new URLSearchParams(window.location.search)
    // const url = window.location.href
    const affiliateLink = queryParameters.get("via")


    // console.log(affiliateLink)

    const handleToSignUp = (pricePlanId:string) => {
        let url = ''
        if(affiliateLink){
            url = `https://app.eu.3folio.finance/register?ReferralId=${referralId}&pricePlan=${pricePlanId}`
        }
        else {
            url = `https://app.eu.3folio.finance/register?pricePlan=${pricePlanId}`
        }
        window.open(url, '_blank')
        // window.open(`https://3folio.finance/register?via=${affiliateLink}&pricePlan=${pricePlanId}`, '_blank')
    }

    const handleContact = () => {
        window.open(`https://calendly.com/michael-3folio/meeting-15-min`, '_blank')
    }

    const [isOpen, setIsOpen] = useState(false)


    return (
        <div className={`border md:shadow-none shadow-cards text-center border-[#EDEEF3] rounded-[24px] md:py-10 ${!textDesc ? 'pt-12' : ''} px-3 mx-2 md:mx-0 md:ml-4 md:mb-0 mb-12 relative overflow-hidden`}>

            {gift && <div className={'bg-accent flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-white '}>
                <GiftIcon /> <span className={'pl-1 text-[12px]'} >Beta Phase</span>
            </div>}

            {individual && <div className={'bg-[#002aa314] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-[#08267B] '}>
                <UserIcon /> <span className={'pl-1 text-[12px] font-semibold'} >Individual</span>
            </div>}

            {commingSoon && <div className={'bg-[#768194] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-white '}>
                <RocketIcon /> <span className={'pl-1 text-[12px]'} >Comming Soon</span>
            </div>}

            {enterprise && <div className={'bg-[#275ef729] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-[#275EF7]'}>
                <EnterpriseIcon /> <span className={'pl-1 text-[12px] font-semibold'} >Enterprise </span><span className={'text-[11px] px-2 py-0.5 font-bold ml-2 text-[#275EF7] bg-white rounded'}>MOST POPULAR</span>
            </div>}

            <h1 className={`font-bold pt-4 ${!textDesc ? 'mb-4' : ''} text-[20px]`}>{type}</h1>
            {price && <h2 className={'font-bold mb-5 text-[20px]'}><span className={'text-[#8D0000] pr-1 font-medium line-through'}>{prevPrice}</span>{price} <span className={'text-[14px] text-[#485066]'}>{enterprise ? 'individual' : isMonthly ? '/month' : '/year'}</span></h2>}
            {free && <h2 className={'font-bold text-accent mb-5 text-[20px]'}>Free</h2>}
            {textDesc && <div className={'flex flex-col mb-4 mt-1 text-[20px]'}>
                <h3 className={'font-semibold text-[#485066] text-[16px]'}>Flexible pricing for every fund and portfolio size</h3>
                <p className={'text-[12px] pt-1 text-[#485066]'}>Fee 0.05% of the annual portfolio value</p>
            </div>}
            <p className={'text-[#83899F] text-[12px] font-medium h-[114px] mb-5'}>{desc}</p>

            <div className={'hidden md:block'}>

            {!enterprise && <button onClick={() => {!enterprise ? handleToSignUp(pricePlanId as string) : handleContact()}} disabled={commingSoon} className={`${commingSoon ? 'bg-[#B3B8C9] cursor-not-allowed' : 'bg-accent w-full'} text-white py-4 rounded-md w-full items-center text-center text-[15px] font-semibold`}>{btnText}</button>}
                {enterprise && <button onClick={() => handleToSignUp(pricePlanId as string)} disabled={commingSoon} className={`bg-accent text-white w-full py-4 rounded-md w-full items-center text-center text-[15px] font-semibold`}>Start 7 day trail!</button>}
            {enterprise && <button onClick={() => {setIsOpen(true)}} disabled={commingSoon} className={`bg-white mt-2  border border-accent text-accent h-[54px] w-full py-4 rounded-md w-full items-center text-center text-[15px] font-semibold`}>Calculate subscription fee</button>}



            {trial ? <p className={`text-accent font-bold text-[14px] pt-3 ${enterprise ? 'h-[57px]' : 'h-[120px]'}`}>{trial}</p> : !trial && !enterprise ? <p className={'h-[120px]'}></p> : <p className={'h-[57px]'}></p>}
            {type === 'Copilot' && cardsData.free.coreFunctions.map((core:any, i) =>
               <p key={`free-core-${i}`} className={`pt-${i === 0 ? '[65px]' : '3.5'} flex justify-center items-center  text-[14px] font-medium text-[#454545] border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot' && <div className={'h-[108px]'}></div>}
            {type === 'Copilot' && cardsData.free.importOptions.map((core:any, i) =>
                <p key={`free-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'} border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot' && <div className={'pt-[79px]'}></div>}
            {type === 'Copilot' && cardsData.free.assetOptions.map((core:any, i) =>
                <p key={`free-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'} border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot' && <div className={'pt-[79px]'}></div>}
            {type === 'Copilot' && cardsData.free.functionsTracking.map((core:any, i) =>
                <p key={`free-functTracking-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'} border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot' && <div className={'pt-[79px]'}></div>}
            {type === 'Copilot' && cardsData.free.functionsAccounting.map((core:any, i) =>
                <p key={`free-funcAcc-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot' && <div className={'pt-[78.5px]'}></div>}
            {type === 'Copilot' && cardsData.free.functionsReporting.map((core:any, i) =>
                <p key={`free-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot' && <div className={'pt-[107px]'}></div>}
            {type === 'Copilot' && cardsData.free.payPerUse.map((core:any, i) =>
                <p key={`free-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}

            {type === 'Copilot Pro' && cardsData.adopter.coreFunctions.map((core:any, i) =>
                <p key={`adopter-fore-${i}`} className={`pt-${i === 0 ? '[65px]' : '3.5'} flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot Pro' && <div className={'h-[108px]'}></div>}
            {type === 'Copilot Pro' && cardsData.adopter.importOptions.map((core:any, i) =>
                <p key={`adopter-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot Pro' && <div className={'pt-[79px]'}></div>}
            {type === 'Copilot Pro' && cardsData.free.assetOptions.map((core:any, i) =>
                <p key={`free-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'} border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot Pro' && <div className={'pt-[79px]'}></div>}
            {type === 'Copilot Pro' && cardsData.adopter.functionsTracking.map((core:any, i) =>
                <p key={`adopter-funcTrack-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot Pro' && <div className={'pt-[79px]'}></div>}
            {type === 'Copilot Pro' && cardsData.adopter.functionsAccounting.map((core:any, i) =>
                <p key={`adopter-funcAcc-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot Pro' && <div className={'pt-[78.5px]'}></div>}
            {type === 'Copilot Pro' && cardsData.adopter.functionsReporting.map((core:any, i) =>
                <p key={`adopter-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Copilot Pro' && <div className={'pt-[107px]'}></div>}
            {type === 'Copilot Pro' && cardsData.adopter.payPerUse.map((core:any, i) =>
                <p key={`adopter-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}



            {type === 'Accounting Pro' && cardsData.starter.coreFunctions.map((core:any, i) =>
                <p key={`starter-core-${i}`} className={`pt-${i === 0 ? '[65px]' : '3.5'} flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Accounting Pro' && <div className={'h-[108px]'}></div>}
            {type === 'Accounting Pro' && cardsData.starter.importOptions.map((core:any, i) =>
                <p key={`starter-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Accounting Pro' && <div className={'pt-[79px]'}></div>}
            {type === 'Accounting Pro' && cardsData.free.assetOptions.map((core:any, i) =>
                <p key={`free-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'} border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Accounting Pro' && <div className={'pt-[79px]'}></div>}
            {type === 'Accounting Pro' && cardsData.starter.functionsTracking.map((core:any, i) =>
                <p key={`starter-funcTrack-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Accounting Pro' && <div className={'pt-[79px]'}></div>}
            {type === 'Accounting Pro' && cardsData.starter.functionsAccounting.map((core:any, i) =>
                <p key={`starter-funcAcc-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Accounting Pro' && <div className={'pt-[78.5px]'}></div>}
            {type === 'Accounting Pro' && cardsData.starter.functionsReporting.map((core:any, i) =>
                <p key={`starter-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Accounting Pro' && <div className={'pt-[107px]'}></div>}
            {type === 'Accounting Pro' && cardsData.starter.payPerUse.map((core:any, i) =>
                <p key={`starter-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}

            {type === 'Enterprise Suite' && cardsData.advanced.coreFunctions.map((core:any, i) =>
                <p key={`advanced-core-${i}`} className={`pt-${i === 0 ? '[65px]' : '3.5'} flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Enterprise Suite' && <div className={'h-[108px]'}></div>}
            {type === 'Enterprise Suite' && cardsData.advanced.importOptions.map((core:any, i) =>
                <p key={`advanced-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Enterprise Suite' && <div className={'pt-[79px]'}></div>}
            {type === 'Enterprise Suite' && cardsData.advanced.assetOptions.map((core:any, i) =>
                <p key={`free-import-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'} border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Enterprise Suite' && <div className={'pt-[79px]'}></div>}
            {type === 'Enterprise Suite' && cardsData.advanced.functionsTracking.map((core:any, i) =>
                <p key={`advanced-funcTrack-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Enterprise Suite' && <div className={'pt-[79px]'}></div>}
            {type === 'Enterprise Suite' && cardsData.advanced.functionsAccounting.map((core:any, i) =>
                <p key={`advanced-funcAcc-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Enterprise Suite' && <div className={'pt-[78.5px]'}></div>}
            {type === 'Enterprise Suite' && cardsData.advanced.functionsReporting.map((core:any, i) =>
                <p key={`advanced-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}
            {type === 'Enterprise Suite' && <div className={'pt-[108px]'}></div>}
            {type === 'Enterprise Suite' && cardsData.advanced.payPerUse.map((core:any, i) =>
                <p key={`advanced-funcReport-${i}`} className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${core.index === 'coming soon' ? 'text-accent' : 'text-[#454545]'}  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${core.index === 'check' && 'min-h-[44px]'}`}><>{ core.index === 'check' ? <CheckIcon /> : core.index}</></p>
            )}

            </div>

            <PricePlanCalculationModal pricePlan={pricePlanId} handleToSignUp={handleToSignUp} isOpen={isOpen} setIsOpen={setIsOpen} key={Math.random()}/>
        </div>
    )
}
