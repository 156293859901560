import React from "react";
import {consensusBackground, consensusColor} from "../../utils";
import {DefaultIntegration} from "../../components/icons/DefaultIntegration";

type Props = {
    row?: any
}
export const SupportedIntegrationsTableRow = ({ row }: Props) => {

    return (
        <tr>
            <td className="border-b border-slate-200 font-medium p-4  ">
                <div className={'flex items-center'}>
                    <div className="relative">
                        <div className="text-gray-300 cursor-pointer flex">
                            <div className="flex h-[35px] border-2 border-gray-300 rounded-lg overflow-hidden">
                                {row?.iconUrl ? <img
                                    src={row?.iconUrl}
                                    alt={row?.displayName}
                                    className={'max-w-[31px]'}
                                /> :
                                <DefaultIntegration />}
                            </div>
                        </div>
                    </div>
                    <div
                        className="pl-2.5"
                    >
                        <p className="flex text-gray-800 text-sm font-medium mb-0.5 truncate">
                            {row?.displayName}
                        </p>

                    </div>
                </div>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <span style={{ color: consensusColor(row?.platformStatus) , backgroundColor: consensusBackground(row?.platformStatus)}} className={`px-2.5 py-0.5 font-medium rounded-[4px]`}>{row?.platformStatus}</span>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <span style={{ color: consensusColor(row?.balanceSync), backgroundColor: consensusBackground(row?.balanceSync)}} className={`px-2.5 py-0.5 font-medium  rounded-[4px]`}>{row?.balanceSync === 'NoNeed' ? '-' : row?.balanceSync}</span>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <span style={{ color: consensusColor(row?.stakingSync), backgroundColor: consensusBackground(row?.stakingSync)}} className={`px-2.5 py-0.5 font-medium rounded-[4px]`}>{row?.stakingSync === 'NoNeed' ? '-' : row?.stakingSync}</span>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <span style={{ color: consensusColor(row?.transactionSync), backgroundColor: consensusBackground(row?.transactionSync)}} className={`px-2.5 py-0.5 font-medium rounded-[4px]`}>{row?.transactionSync}</span>
            </td>
        </tr>
    )
}