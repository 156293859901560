import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header";

import Footer from "../../components/Footer";
import { SupportedIntegrationsTableRow} from "./SupportedIntegrationsTableRow";
import { T } from "@tolgee/react";
import {InfoBox} from "../../components/InfoBox";


export const SupportedIntegrations = () => {

    const [platforms, setPlatforms] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [banner, setBanner] = useState({});

    const handleSearchChange = (event:any) => {
        setSearchTerm(event.target.value);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null);


    const [selectedOption, setSelectedOption] = useState<string>('all');
    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const filteredItems = platforms.filter((item:any) => {
        // Filter by search text
        const searchTextMatch = item?.displayName.toLowerCase().includes(searchTerm.toLowerCase());

        // Filter by selected category (if any)
        const categoryMatch = selectedOption !== 'all' ? item?.integrationCategory === selectedOption : true;

        return searchTextMatch && categoryMatch;
    });


    useEffect(() => {
        fetch(`https://coredeveloper.azurewebsites.net/api/IntegrationInfo/status`)
            .then((response) => response.json())
            .then((actualData) => {
                setPlatforms(actualData.platforms)
                setBanner(actualData.statusBanner)
            });


    }, [])

    return (
        <>
            <Header />
            {/*<div className={'max-w-screen-xl flex w-full mx-auto pt-6 pb-4'}>*/}
            {/*    {Object.keys(banner).length !== 0 &&*/}
            {/*    <InfoBox banner={banner} />*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={'flex max-w-screen-xl mx-auto pb-0 pt-8'}>
                <h1 className={' flex  text-[#0A1B39] text-[20px] font-bold font-tt'}>
                    <T keyName={'list_integrations'} />
                </h1>
            </div>
            <div className={'flex max-w-screen-xl mx-auto pt-4 pb-5'}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search..."
                        className={'block w-64 p-2 pl-3 text-xs text-gray-900 h-9 border shadow-none  outline-none rounded-lg focus-visible:border-blue-500 focus:border-blue-500  '}
                 />
                <div className={''} ref={dropdownRef}>
                    <button id="status" name="status"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className="border border-gray-300 text-gray-600 focus:border-blue-500 ml-2 bg-white font-medium rounded-lg text-xs px-5 py-2.5 h-9 text-center inline-flex items-center hover:border-gray-500 transition duration-150 ease-in-out"
                            type="button">Category
                        <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true"
                                                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="m1 1 4 4 4-4"></path>
                        </svg>
                    </button>
                    {dropdownOpen && <div id="dropdown"
                         className="z-10 absolute ml-2 w-32 bg-white divide-y divide-gray-100 rounded-lg shadow w-auto p-1.5 dark:bg-gray-700">
                        <ul className="text-sm text-gray-700 m-0 dark:text-gray-200"
                            aria-labelledby="dropdownDefaultButton">
                            <li className={'p-2'}>
                                <div className="flex items-center cursor-pointer">
                                    <input id="categories-all" type="radio" value="all" name="categories"
                                           checked={selectedOption === 'all'}
                                           onChange={(e) => handleOptionChange(e.target.value)}
                                           className="w-4 h-4 outline-none focus:ring-[0px] mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                    <label htmlFor="categories-all"
                                           className="text-sm w-full font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                        All
                                    </label>
                                </div>
                            </li>
                            <li className={'p-2'}>
                                <div className="flex items-center cursor-pointer">
                                    <input id="categories-1" type="radio" value="Chain" name="categories"
                                           onChange={(e) => handleOptionChange(e.target.value)}
                                           checked={selectedOption === 'Chain'}
                                           className="w-4 h-4 outline-none focus:ring-[0px] mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label htmlFor="categories-1"
                                               className="text-sm w-full font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                            Chain
                                        </label>
                                </div>
                            </li>
                            <li className={'p-2'}>
                                <div className="flex items-center cursor-pointer">
                                    <input id="categories-2" type="radio" value="Cex" name="categories"
                                           onChange={(e) => handleOptionChange(e.target.value)}
                                           checked={selectedOption === 'Cex'}
                                           className="w-4 h-4 outline-none focus:ring-[0px]  mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label htmlFor="categories-2"
                                               className="ms-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                            CEX
                                        </label>
                                </div>
                            </li>
                        </ul>
                    </div>}
                </div>


            </div>

            <div className="max-w-screen-xl  mx-auto bg-gray-50 rounded-xl  mb-12">
                <div className="relative rounded-xl overflow-auto">
                    <div className="mt-3">
                        <table className="table-auto w-full text-sm overflow-x-auto">
                            <thead>
                            <tr>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-left ">Integration</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Status</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Balance Sync</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Staking Sync</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Transaction Sync</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white ">
                            {filteredItems.length === 0 &&
                                <tr>
                                    <td colSpan={5}><p className={'p-4 items-start text-center'}><T keyName={'no_integrations'} /></p></td> </tr>}
                            {filteredItems && filteredItems.map((row:any) =>
                                <SupportedIntegrationsTableRow key={row?.displayName} row={row} />
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>




            <Footer />
        </>
    )
}