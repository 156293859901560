import {LayersIcon} from "../../components/icons/LayersIcon";
import {ReactElement} from "react";
import {BookIcon} from "../../components/icons/BookIcon";
import {DiagramIcon} from "../../components/icons/DiagramIcon";
import {ListIcon} from "../../components/icons/ListIcon";
import {DownloadIcon} from "../../components/icons/DownloadIcon";
import {CoinsIcon} from "../../components/icons/CoinsIcon";
import { T } from "@tolgee/react";

function ProblemCards() {
    const cards: ProblemCard[] = [
        {
            title: 'portfolio_insights',
            description: 'portfolio_insights_desc',
            icon: <LayersIcon />
        },
        {
            title: 'book_keeping',
            description: 'book_keeping_desc',
            icon: <BookIcon />
        },
        {
            title: 'integrations',
            description: 'integrations_desc',
            icon: <DiagramIcon />
        },
        {
            title: 'action_logging',
            description: 'action_logging_desc',
            icon: <ListIcon />
        },
        {
            title: 'data_export_import',
            description: 'data_export_import_desc',
            icon: <DownloadIcon />
        },
        {
            title: 'traceable_asset_prices',
            description: 'traceable_asset_prices_desc',
            icon: <CoinsIcon />
        }
    ]
    return( 
        <div className="max-w-screen-xl box m-auto mb-36 max-xl:p-5" id="features">
            <br/>
            <h1 className="text-2xl md:w-[75%] mx-auto w-full font-bold text-[#0A1B39] mb-3 max-md:text-2xl">
                    <span className={'text-accent'}>"<T keyName={'our_enterprise_copilot'} />"</span><T keyName={'our_enterprise_copilot2'} />
            </h1>
            <br/>
            <p className="font-medium text-slate-400 mb-10">
                {/*High quality, privacy and security is expensive! Stop using tools that are made for mainstream and <br/> start using tools that meet your professional requirements to reduce uncertainty, risk and effort.*/}
            </p>
            <br/>
            <div className="flex justify-items-stretch flex-wrap">
                {
                    cards.map((card, i) => (
                        <div className="flex flex-col items-center w-full sm:w-6/12 md:w-4/12 mb-8" key={"problemCard-" + i}>
                            <div className={'flex flex-col items-center h-full justify-start p-7 mx-4 rounded-[16px] shadow-cards'}>
                                <div className={'flex p-4 rounded-md bg-[#F0F5FF]'}>
                                    {card.icon}
                                </div>
                                <h2 className="font-semibold text-[#061C3D] text-[18px] pt-4">
                                    <T keyName={card.title} />
                                </h2>
                                <p className="text-[#42526B] text-[14px] pt-3 md:w-10/12 lg:w-full">
                                    <T keyName={card.description} />
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div> 
        </div>
    )
}

export default ProblemCards;

interface ProblemCard {
    title: string;
    description: string;
    icon: ReactElement
}