import { T } from "@tolgee/react";
import { useEffect, useRef } from "react";
import { DashIcon } from "../../components/icons/DashIcon";

function FeaturesIllustrated() {
    const featureRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-fadeIn');
                }
            });
        }, { threshold: 0.1 });

        featureRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            if (featureRefs.current) {
                featureRefs.current.forEach((ref) => {
                    if (ref) {
                        observer.unobserve(ref);
                    }
                });
            }
        };
    }, []);

    const features: featureObject[] = [
        {
            title: "",
            subtitle: "tracking_subtitle",
            desc: 'tracking_desc',
            image: "./tracking.svg"
        },
        {
            title: "",
            subtitle: "rm_accounting_subtitle",
            desc: "rm_accounting_desc",
            image: "./accounting.svg"
        },
        {
            title: "",
            subtitle: "reporting_subtitle",
            desc: "reporting_desc",
            image: "./report.svg"
        },
        {
            title: "",
            subtitle: "subtitle_4",
            desc: "desc_4",
            image: "./report.svg"
        }
    ];

    const style = `
        .inset-left::after, .inset-right::after {
            content: '';
            height: 100%;
            width: 2px;
            background: #275EF7;
            position: absolute;
            top: 0;
        }
        .inset-left::after {
            right: -2px;
        }
        .inset-right::after {
            left: 0;
        }
    `;

    return (
        <div className="max-w-screen-xl box m-auto md:mb-24 mb-12 max-xl:p-5" id="guideline">
            <style>{style}</style>
            <h1 className="text-[24px] font-bold text-[#101828] mb-3 max-md:text-3xl">
                <span className="text-accent">
                    "<T keyName="roadmap_title" />"
                </span>
                <T keyName="roadmap_title_2" />
            </h1>
            <p className="font-medium text-[#667085] text-[20px] mb-10 lg:w-[70%] m-auto">
                <T keyName="roadmap_subtitle" />
            </p>
            <div className="flex justify-items-stretch flex-wrap pt-12">
                {features.map((feature, i) => (
                    <div
                        className={`flex flex-row basis-full flex-wrap ${i % 2 !== 0 ? 'flex-row-reverse' : ''} opacity-0`} // Initial state with opacity-0
                        key={`feature-${i}`}
                        // @ts-ignore
                        ref={(el) => (featureRefs.current[i] = el)}
                    >
                        <div className={`box relative mt-2 md:my-auto pb-16 max-sm:pb-12 w-1/2 max-sm:w-full max-sm:mx-auto ${i % 2 === 0 ? 'sm:pr-10 inset-left' : 'sm:pl-10 inset-right'}`}>
                            <p className="relative text-accent text-left font-bold uppercase text-[16px] max-md:mt-4 max-md:text-sm md:mb-0 mb-3 ob">
                                <DashIcon /> <span className="pl-7"><T keyName={feature.subtitle} /></span>
                            </p>
                            <h1 className="font-bold text-[#191919] text-[28px] text-left mb-4 max-md:text-[28px]">
                                <T keyName={feature.title} />
                            </h1>
                            <div className="flex" key={`bulletPoint-${i}`}>
                                <p className="text-[#667085] text-[20px] text-left" key={`bulletPoint-${i}`}>
                                    <T keyName={feature.desc} />
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FeaturesIllustrated;

interface featureObject {
    title: string;
    subtitle: string;
    desc: string;
    image: string;
}
