import React from "react";

export const DeutschFlagIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_3655_123625)">
                <path d="M19.378 13.4782C19.78 12.3949 20 11.2231 20 9.99991C20 8.77671 19.78 7.60503 19.378 6.52167L10 5.6521L0.621992 6.52167C0.220039 7.60503 0 8.77671 0 9.99991C0 11.2231 0.220039 12.3949 0.621992 13.4782L10 14.3477L19.378 13.4782Z" fill="#F0F0F0"/>
                <path d="M10 20C14.2996 20 17.9651 17.2863 19.378 13.4783H0.621948C2.03492 17.2863 5.70031 20 10 20Z" fill="#D80027"/>
                <path d="M10 0C5.70031 0 2.03492 2.71375 0.621948 6.52176H19.378C17.9651 2.71375 14.2996 0 10 0Z" fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0_3655_123625">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}