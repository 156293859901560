import React, {useRef, useState} from "react";
import ItemsCarousel from 'react-items-carousel';
import {TestimonialsArray} from "../../utils";
import Stars from "../../img/stars.svg";
import {CustomDots} from "./CustomDots";
import {useSwipeable} from "react-swipeable";
import {T} from "@tolgee/react";

export const Testimonials = () => {

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 10;

   const width = window.innerWidth


    const handlers = useSwipeable({
        onSwipedLeft: () => setActiveItemIndex((prev) => prev === TestimonialsArray.length - 1 ? 0 : prev + 1),
        onSwipedRight: () => setActiveItemIndex((prev) => prev === 0 ? TestimonialsArray.length - 1 : prev - 1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    return (
        <div {...handlers} className="max-w-screen-xl box m-auto mb-32 max-xl:p-5">
            <h2 className={'text-[24px] font-bold pb-8'}><T keyName={'our_work_appreciated'} /></h2>
            <div className={'px-10'}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={width > 1024 ? 3 : width < 768 ? 1 : 2}
                    gutter={10}
                    infiniteLoop
                    disableSwipe={true}

                >
                    {TestimonialsArray.map((testimonial, i) =>
                        <div key={i}  className={'flex justify-center m-2 mb-6'}>
                            <div className={'w-full cursor-grab p-7 items-center h-full justify-start shadow-testimonial rounded-[16px] '}>
                                <div className={'flex flex-row items-center'}>
                                    <img className="rounded-full flex w-10 h-[80px] w-[80px] mr-4" src={testimonial.avatarUrl} alt={testimonial.name}/>
                                    <div className={'flex flex-col justify-start text-start'}>
                                        <h2 className={'flex text-[18px] font-bold text-[#0F172A]'}>{testimonial.name}</h2>
                                        <h3 className={'flex text-[16px] font-medium text-[#475569]'}>{testimonial.position}</h3>
                                    </div>
                                </div>
                                <div className={'flex pt-3'}>
                                    <img src={Stars} alt="stars" className="w-20 mt-2"/>
                                </div>
                                <div className={'flex pt-3'}>
                                    <p className={'text-start text-[#303A42] text-[18px] font-medium'}>
                                        {testimonial.quote}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </ItemsCarousel>
            </div>
            <div className={'flex flex-row justify-center'}>
            {TestimonialsArray.map((tst, i) =>
                <CustomDots active={activeItemIndex} index={i} onClick={() => setActiveItemIndex(i)} />
            )}
            </div>
        </div>
    )
}