import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React from "react";
import ReactGA from "react-ga";
import {TwitterIcon} from "../../components/icons/TwitterIcon";
import {LinkedinIcon} from "../../components/icons/LinkedinIcon";
import { T } from "@tolgee/react";
// @ts-ignore
import ItemsCarousel from 'react-items-carousel';
import {useState} from "react";

export const AdvisoryBoard = () => {
    const { trackEvent } = useMatomo();
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 20;



    const boardMembers = [
        {
            name: "Florian Wimmer",
            job: "CEO - Blockpit AG",
            position: "Crypto Tax & Regulation",
            image: "/DEMO/images/advisory-1.jpg",
            linkedin: "https://www.linkedin.com/in/florian-wimmer",
            twitter: "https://twitter.com/FlorianWimmerAT%20%20"
        },
        {
            name: "Peter Augustin",
            job: "Founder - Tigris Web3 Fund",
            position: "Crypto Funds & Web3",
            image: "/DEMO/images/advisory-2.jpg",
            linkedin: "https://www.linkedin.com/in/peter-augustin-293475186/"
        },
        {
            name: "Martin Roy",
            job: "Mergers & Acquisitions",
            position: "Global Strategy & Finance",
            image: "/DEMO/images/advisory-3.jpg",
            linkedin: "https://www.linkedin.com/in/martin-roy-31711b1/"
        },
        {
            name: "Franz Großmann",
            job: "IT Infrastructure & Security Expert",
            position: "Cyber Security & Privacy",
            image: "/DEMO/images/advisory-4.jpg",
            linkedin: "https://www.linkedin.com/in/franz-grossmann-schoellernetworkcontrol/"
        }
    ];

    const onSocialMediaClick = (socialMedia: 'linkedin' | 'twitter' | 'xing', name: string) => {
        trackEvent({ category: 'advisory board', action: `${socialMedia} of ${name} clicked` });
        ReactGA.event({
            category: 'advisory board',
            action: `${socialMedia} of ${name} clicked`,
            label: 'Advisory Board',
            nonInteraction: true
        });
    }
    
    return(
        <div className={'max-w-screen-xl box m-auto md:mb-32 mb:16  max-xl:p-5'}>

                <p className="font-semibold text-black text-[24px] pb-10">
                    <T keyName={'advisory_board'} />
                </p>

            <div className={' md:hidden block'}>
                <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={1}
                gutter={10}
                infiniteLoop
                outsideChevron
                chevronWidth={chevronWidth}
                leftChevron={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" stroke={'#98a2b3'} />
                </svg>
                }
                rightChevron={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" stroke={'#98a2b3'} />
                </svg>
                }
            >
                {boardMembers.map((boardMember, i) => (
                    <div className="flex flex-col  self-stretch items-center w-full  mb-4 " key={"AdvisorBoard-" + i}>
                        <div className={'flex flex-col items-center h-full justify-start p-6 mx-2 rounded-[16px] self-stretch shadow-cards '} >

                            <img src={boardMember.image} alt="logo" className="my-3 object-contain h-[80px] border-r-6 rounded-full" key={"boardMemberImage-" + i} />
                            <h2 className="text-[#101828] text-[18px] leading-7 font-bold">
                                {boardMember.name}
                            </h2>
                            <p className="font-medium text-[14px] leading-6 text-[#312E81]">
                                {boardMember.job}
                            </p>
                            <p className="text-[#667085] text-[14px] leading-6 w-full">
                                {boardMember.position}
                            </p>
                            <div className="flex flex-row">
                                {
                                    boardMember.twitter &&
                                    <a href={boardMember.twitter} className={'mt-3 px-1'} target="_blank" rel="noreferrer" onClick={() => onSocialMediaClick('twitter', boardMember.name)}>
                                        <TwitterIcon  />
                                    </a>
                                }
                                {
                                    boardMember.linkedin &&
                                    <a href={boardMember.linkedin} className={'mt-3  px-1'} target="_blank" rel="noreferrer" onClick={() => onSocialMediaClick('linkedin', boardMember.name)}>
                                        <LinkedinIcon />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </ItemsCarousel>
            </div>


                <div className="flex hidden md:flex flex-wrap">
                    {boardMembers.map((boardMember, i) => (
                        <div className="flex flex-col items-center w-full sm:w-6/12 md:w-3/12 mb-4 " key={"AdvisorBoard-" + i}>
                            <div className={'flex flex-col items-center h-full justify-start p-6 mx-2 rounded-[16px] self-stretch shadow-cards '} >

                                <img src={boardMember.image} alt="logo" className="my-3 object-contain h-[80px] border-r-6 rounded-full" key={"boardMemberImage-" + i} />
                                <h2 className="text-[#101828] text-[18px] leading-7 font-bold">
                                    {boardMember.name}
                                </h2>
                                <p className="font-medium text-[14px] leading-6 text-[#312E81]">
                                    {boardMember.job}
                                </p>
                                <p className="text-[#667085] text-[14px] leading-6 w-full">
                                    {boardMember.position}
                                </p>
                                <div className="flex flex-row">
                                    {
                                        boardMember.twitter &&
                                        <a href={boardMember.twitter} className={'mt-3 px-1'} target="_blank" rel="noreferrer" onClick={() => onSocialMediaClick('twitter', boardMember.name)}>
                                            <TwitterIcon  />
                                        </a>
                                    }
                                    {
                                        boardMember.linkedin &&
                                        <a href={boardMember.linkedin} className={'mt-3  px-1'} target="_blank" rel="noreferrer" onClick={() => onSocialMediaClick('linkedin', boardMember.name)}>
                                            <LinkedinIcon />
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

    </div>
    )
}



