import React from "react";

export const LinkIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clipPath="url(#clip0_124_469)">
                <path d="M7.01248 1.10635C7.01248 1.38689 7.23818 1.6126 7.51872 1.6126H9.6724L4.96638 6.3165C4.7681 6.51478 4.7681 6.83541 4.96638 7.03158C5.16466 7.22775 5.48529 7.22986 5.68146 7.03158L10.3854 2.32768V4.48135C10.3854 4.76189 10.6111 4.9876 10.8916 4.9876C11.1722 4.9876 11.3979 4.76189 11.3979 4.48135V1.10635C11.3979 0.825801 11.1722 0.600098 10.8916 0.600098H7.51872C7.23818 0.600098 7.01248 0.825801 7.01248 1.10635ZM2.11873 1.2751C1.27919 1.2751 0.599976 1.95432 0.599976 2.79385V9.88135C0.599976 10.7209 1.27919 11.4001 2.11873 11.4001H9.20623C10.0458 11.4001 10.725 10.7209 10.725 9.88135V7.18135C10.725 6.9008 10.4993 6.6751 10.2187 6.6751C9.93818 6.6751 9.71247 6.9008 9.71247 7.18135V9.88135C9.71247 10.1619 9.48677 10.3876 9.20623 10.3876H2.11873C1.83818 10.3876 1.61248 10.1619 1.61248 9.88135V2.79385C1.61248 2.5133 1.83818 2.2876 2.11873 2.2876H4.81873C5.09927 2.2876 5.32498 2.06189 5.32498 1.78135C5.32498 1.5008 5.09927 1.2751 4.81873 1.2751H2.11873Z" fill="#275EF7"/>
            </g>
            <defs>
                <clipPath id="clip0_124_469">
                    <rect width="10.8" height="10.8" fill="white" transform="translate(0.599976 0.600098)"/>
                </clipPath>
            </defs>
        </svg>
    )
}