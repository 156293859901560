import { T } from "@tolgee/react";

function PressLogos() {
    const logos = ["DEMO/logos/cointelegraph.png", "DEMO/logos/derbrutkasten.png", "DEMO/logos/trending-topics-schrift_square.png", "DEMO/logos/yahoo-finance.png"];

    return(
        <div className="my-20">
            <p className="text-[#98a2b3cc] font-semibold text-[24px]">
               <T keyName={'known_from'} />
            </p>
            <div className="flex flex-row flex-wrap justify-center">
                {logos.map((logo, i) => (
                    <img src={logo} alt="logo" height="55px" className="max-md:min-w-full grayscale brightness-105 max-h-[55px] m-6 object-contain" key={"pressLogo-" + i} />
                ))}
            </div>
        </div>
    )
}

export default PressLogos;