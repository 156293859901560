import { T } from '@tolgee/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

function HeroCarousel() {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const carouselWords =[""]; //["crypto_funds","small_large_companies","family_offices"] ; // TODO: Load this from a CMS

    const [carouselIndex, setCarouselIndex] = useState(1);
    const [carouselWord, setCarouselWord] = useState(carouselWords[0]);
    const [isVisible, setIsVisible] = useState(true);
    const ANIMATION_DURATION = 1000;
    const INTERVAL = 3000;

    const show = {
        opacity: 1,
        y: 0,
        transition: {
        duration: ANIMATION_DURATION / 1000
        }
    };
  
  const hide = {
    opacity: 0,
    x: 20,
    transition: {
      duration: ANIMATION_DURATION / 1000
    },
    transitionEnd: {
      x: -20
    },
  };


  // carousel effect every 2 seconds for the title
  useEffect(() => {
    // create interval and change index
    const interval = setInterval(() => {
      setIsVisible(false);

      setTimeout(() => {
        if(carouselIndex < carouselWords.length - 1) {
          setCarouselIndex(carouselIndex + 1);
        } else {
          setCarouselIndex(0);
        }
        
        // update current word
        setCarouselWord(carouselWords[carouselIndex]);
      }, ANIMATION_DURATION);

      setTimeout(() => {
        setIsVisible(true);
      }, ANIMATION_DURATION);
    }, INTERVAL);
  
    return () => clearInterval(interval);
  }, [carouselIndex, carouselWords]);
  
  return(
    <div className='flex flex-row max-sm:flex-col max-sm:text-center  flex-nowrap  max-md:justify-center'>
        <h1 className="text-[28px] whitespace-pre-wrap max-sm:justify-center max-sm:text-center text-[#170F49] font-bold mt-4 max-sm:mb-0 mb-4 mr-2 font-tt leading-tight w-fit max-sm:w-full text-left whitespace-nowrap max-md:text-3xl">
          <T keyName={'made_for'} />
        </h1>
        {/*<motion.h1 */}
        {/*className="text-[28px] text-white max-sm:justify-center max-sm:text-center font-extrabold mt-1 sm:mt-4 mb-4 font-tt leading-tight w-fit text-left pl-1 underline w-full max-sm:w-full whitespace-nowrap max-md:text-[28px]"*/}
        {/*animate={isVisible ? show : hide}*/}
        {/*style={{ color: '#170F49', textDecorationColor: '#170F49'}}*/}
        {/*>*/}
        {/*    <T keyName={carouselWord} />*/}
        {/*  /!*{carouselWord}.*!/*/}
        {/*</motion.h1>*/}
    </div>
  )
}

export default HeroCarousel;