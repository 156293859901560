import React from "react";

export const LayersIcon = () => {

    return (
        <><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M8.75 11.875L2.5 15L14.5528 21.0264C14.7168 21.1083 14.7988 21.1493 14.8848 21.1655C14.9609 21.1798 15.0391 21.1798 15.1152 21.1655C15.2012 21.1493 15.2832 21.1083 15.4472 21.0264L27.5 15L21.25 11.875M8.75 18.125L2.5 21.25L14.5528 27.2764C14.7168 27.3583 14.7988 27.3993 14.8848 27.4155C14.9609 27.4298 15.0391 27.4298 15.1152 27.4155C15.2012 27.3993 15.2832 27.3583 15.4472 27.2764L27.5 21.25L21.25 18.125M2.5 8.74996L14.5528 2.72357C14.7168 2.64158 14.7988 2.60058 14.8848 2.58445C14.9609 2.57016 15.0391 2.57016 15.1152 2.58445C15.2012 2.60058 15.2832 2.64158 15.4472 2.72357L27.5 8.74996L15.4472 14.7764C15.2832 14.8583 15.2012 14.8993 15.1152 14.9155C15.0391 14.9298 14.9609 14.9298 14.8848 14.9155C14.7988 14.8993 14.7168 14.8583 14.5528 14.7764L2.5 8.74996Z" stroke="#317BFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
            </>
    )
}