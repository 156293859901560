function Button(props: ButtonProps) {
    const generalClassName = "button rounded-lg m-1.5 font-semibold transition-colors";
    const colorClassName = props.color === "primary" ? 
                                "bg-accent hover:bg-blue-700 text-white rounded-[8px] border border-[#0B63E5]"
                                : 
                            props.color === "secondary" ? 
                                "bg-white text-[#111827] hover:bg-slate-100 hover:text-black border border-[#EDEEF3]"
                                : 
                            props.color === "outline" ? 
                                "bg-transparent text-white border border-blue-500 border-solid hover:border-blue-700 hover:text-slate-200"
                                :
                                "bg-white hover:bg-gray-100 text-gray-800";

    const sizeClassName = props.size === "small" ?
                                "py-2 px-3 text-xs rounded-[8px]"
                                :
                            props.size === "medium" ?
                                "py-1.5 px-5 text-sm"
                                :
                                "py-2 px-10 text-base";

    const className = generalClassName + " " + colorClassName + " " + sizeClassName + " " + props.className;

    return (
    <button className={className}
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type}
        style={props.style}>
            <div className="flex justify-center">
                { props.icon && <i className={"iconoir-" + props.icon + " mr-2 pt-[4px]"}></i> }
                { props.text }
            </div>
    </button>
  )
}

export default Button;

interface ButtonProps {
    text?: string | any;
    color: "primary" | "secondary" | "outline";
    size: "small" | "medium" | "large";
    onClick?: () => void;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    style?: React.CSSProperties;
    icon?: string;
    className?: string;
}
