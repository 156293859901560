import React from 'react'

export const DefaultIntegration = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            className="m-auto"
        >
            <g id="draw">
                <path
                    id="Icon"
                    d="M5 17.5V7.5M5 17.5C3.89543 17.5 3 18.3954 3 19.5C3 20.6046 3.89543 21.5 5 21.5C6.10457 21.5 7 20.6046 7 19.5M5 17.5C6.10457 17.5 7 18.3954 7 19.5M5 7.5C6.10457 7.5 7 6.60457 7 5.5M5 7.5C3.89543 7.5 3 6.60457 3 5.5C3 4.39543 3.89543 3.5 5 3.5C6.10457 3.5 7 4.39543 7 5.5M7 5.5H17M17 5.5C17 6.60457 17.8954 7.5 19 7.5M17 5.5C17 4.39543 17.8954 3.5 19 3.5C20.1046 3.5 21 4.39543 21 5.5C21 6.60457 20.1046 7.5 19 7.5M19 7.5V17.5M19 17.5C17.8954 17.5 17 18.3954 17 19.5M19 17.5C20.1046 17.5 21 18.3954 21 19.5C21 20.6046 20.1046 21.5 19 21.5C17.8954 21.5 17 20.6046 17 19.5M7 19.5H17"
                    stroke="#83899F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    )
}
