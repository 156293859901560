import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Matomo
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import {DevTools, FormatSimple, Tolgee, TolgeeProvider} from "@tolgee/react";
import * as localeEn from './i18n/en.json'
import * as localeDe from './i18n/de.json'


const instance = createInstance({
  urlBase: 'https://matomo.crand.io/',
  siteId: 1,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  // linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST'
  }
})



const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .init({
      availableLanguages: ['en', 'de'],
      defaultLanguage: 'de',
        // apiUrl: 'https://app.tolgee.io',
        // apiKey: 'tgpak_gq4tkns7gu4dkztmny2tmolborvtezzxmftxkz3jou3gomlwnq',
      staticData: {
        en: localeEn as any,
        de: localeDe as any,
      },
    });





const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
        <TolgeeProvider
            tolgee={tolgee}

        >
            <App />
        </TolgeeProvider>
    </MatomoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
