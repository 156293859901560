import React from "react";
import {CheckIconCircle} from "../../components/icons/CheckIconCircle";

export const CoPilotList = () => {

    return (
        <div className={'flex justify-start flex-col'}>
        <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>2 Workspaces included</span>
        </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}> Unlimited sources and users</span>
            </div>
   
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Ongoing portfolio details</span>
            </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Accounting extention</span>
            </div>
            <div className={'flex justify-start'}>
                <div className={'flex'}>
                    <CheckIconCircle />
                </div>
                <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Scheduled net asset value determination</span>
            </div>
        </div>
    )
}