export const WEBSITE_SHOW_PRICE_LIST = true
export const WEBSITE_SHOW_TESTIMONIALS = false

export function infoBackground(status: string) {
    switch (status) {
        case 'Information':
            return '#EFF6FF'
            break
        case 'Issue':
            return '#dcb0b04d'
            break
        case 'Maintenance':
            return '#e3a92314'
            break
        default:
            return '#EFF6FF'
            break
    }
}

export function infoBtnBackground(status: string) {
    switch (status) {
        case 'Information':
            return '#DBEAFE'
            break
        case 'Issue':
            return '#EAD2D2'
            break
        case 'Maintenance':
            return '#F5EBD4'
            break
        default:
            return '#DBEAFE'
            break
    }
}

export function infoBtnColor(status: string) {
    switch (status) {
        case 'Information':
            return '#3B82F6'
            break
        case 'Issue':
            return '#A43333'
            break
        case 'Maintenance':
            return '#CF9A20'
            break
        default:
            return '#3B82F6'
            break
    }
}

export function consensusColor(status: string) {
    switch (status) {
        case 'Automated':
        case 'Ok':
            return '#148800'
            break
        case 'Manually':
            return '#275EF7'
            break
        case 'Maintenance':
            return '#1F2937'
            break
        case 'Issue':
            return '#8D0000'
            break
        default:
            return '#373A45'
            break
    }
}

export function consensusBackground(status: string) {
    switch (status) {
        case 'Automated':
        case 'Ok':
            return '#E8F3E6'
            break
        case 'Manually':
            return '#EAF2FF'
            break
        case 'Maintenance':
            return '#EDEEF3'
            break
        case 'Issue':
            return '#F4E6E6'
            break
        default:
            return '#fff'
            break
    }
}

export const coreFunctions = [
    {
        color: "#1AB200",
        title: "Limit of sources",
        tooltip: "Limit of integrations you can add to your workspace at the same time.",
    },
    {
        color: "#1AB200",
        title: "Limit of users",
        tooltip: "Limit of users you can add/invite to your workspace at the same time.",
    },
    {
        color: "#1AB200",
        title: "Included transactions*",
        tooltip: "Limit of fiat and crypto transactions you can sync or add to your workspace per tax year. Talk to sales team if you need an upgrade.",
    },
    {
        color: "#1AB200",
        title: "Tracking Module",
        tooltip: "This co-pilot gives you a daily overview of your portfolio value, asset movements, allocation and performance.",
    },
    {
        color: "#1AB200",
        title: "Wealth Manager Module",
        tooltip: "This Co-Pilot processes your qualified data in reports so that they can also be used as preparation for the proof of origin.",
    },
    {
        color: "#F76527",
        title: "Accounting Module**",
        tooltip: "This co-pilot assists you in preparing your transactions for your accountant and tax advisor.",
    },
    {
        color: "#F76527",
        title: "Reporting Module",
        tooltip: "This co-pilot assists you in preparing your transactions for your accountant and tax advisor.",
    },
    {
        color: "#1AB200",
        title: "Support level",
        tooltip: "Different target groups require different response times in the event of a fault. We provide various options for this.",
    },
    {
        color: "#F76527",
        title: "User action logger",
        tooltip: "When working in a team and with the tax consultant, it is important that user activities are recorded in order to document changes to data records in a traceable manner.",
    },
]

export const importOptions = [
    {
        color: "#1AB200",
        title: "Exchange, wallet and bank APIs",
        tooltip: "Please note that our import options may vary. You can find an overview of supported integrations on our homepage.",
    },
    {
        color: "#1AB200",
        title: "Local fiat & crypto wallets",
        tooltip: "It is possible to create local depots to manually manage holdings and transactions for a specific purpose.",
    },
    {
        color: "#1AB200",
        title: "Self-serviced assets",
        tooltip: "If you cannot find an asset in our broad asset catalog, you can simply create your own.",
    },
    {
        color: "#1AB200",
        title: "Balances auto syncer",
        tooltip: "For synchronized integrations, we offer a time-controlled synchronization process.",
    },
    {
        color: "#1AB200",
        title: "Transactions auto syncer",
        tooltip: "For synchronized integrations, we offer a time-controlled synchronization process.",
    },
    {
        color: "#1AB200",
        title: "Transaction upload (.csv, .xls)",
        tooltip: "For some integrations, only a manual importer via file upload is currently available for transactions.",
    }
]

export const assetOptions = [
    {
        color: "#1AB200",
        title: "250,000+ supported assets",
        tooltip: "We have a huge asset database that has been built up since 2017.",
    },
    {
        color: "#1AB200",
        title: "Derivates",
        tooltip: "We are constantly striving to expand our database.",
    },
    {
        color: "#1AB200",
        title: "Commodities",
        tooltip: "We are constantly striving to expand our database.",
    },
    {
        color: "#1AB200",
        title: "Non-Fungible Token (NFT)",
        tooltip: "We are constantly striving to expand our database.",
    },
    {
        color: "#1AB200",
        title: "Foreign Currencies (Fiat)",
        tooltip: "We support a wide range of foreign currencies with daily rating information.",
    },
    {
        color: "#1AB200",
        title: "5+ Years historical pricing data",
        tooltip: "A historical valuation of assets is possible.",
    }
]

export const functionsTracking = [
    {
        color: "#1AB200",
        title: "Portfolio overview",
        tooltip: "You will find key indications to portfolio performance, allocation and value development.",
    },
    {
        color: "#1AB200",
        title: "Synchronized and local sources",
        tooltip: "Simply manage your sources of wealth in one central location.",
    },
    {
        color: "#1AB200",
        title: "Self-serviced assets",
        tooltip: "It is possible to add self-managed assets of different types if they are not yet supported by us.",
    },
    {
        color: "#1AB200",
        title: "Net asset value determination",
        tooltip: "The net asset value of your portfolio is calculated automatically on a daily basis.",
    },
    {
        color: "#1AB200",
        title: "Asset prices with origin reference",
        tooltip: "Our price lists have a source reference and are based on consensus pricing.",
    },
    {
        color: "#1AB200",
        title: "Net asset value history",
        tooltip: "The net asset value of a portfolio is recorded daily and stored for a certain period of time.",
    },
    {
        color: "#1AB200",
        title: "Portfolio details history",
        tooltip: "We endeavor to provide you with a detailed insight into the holdings and cash flow of your portfolio.",
    },
    {
        color: "#1AB200",
        title: "User permission management",
        tooltip: "Simply manage the rules such as read and write permissions for your team members.",
    },
    {
        color: "#F76527",
        title: "Asset inventory report",
        tooltip: "Export the asset holdings of your portfolio at any time during the recording.",
    }
]

export const functionsAccounting = [
    {
        color: "#1AB200",
        title: "Smart transaction book",
        tooltip: "We combine fiat and crypto transactions and enable complex mapping.",
    },
    {
        color: "#1AB200",
        title: "Customizable transaction labels",
        tooltip: "Mark your transactions so that they can be valued correctly according to company and tax law.",
    },
    {
        color: "#1AB200",
        title: "Tax advisor collaboration*",
        tooltip: "Invite your tax advisor to your workspace to get your transactions faster ready for tax reporting.",
    },
    {
        color: "#1AB200",
        title: "Asset inventory history",
        tooltip: "Fix and evaluate your asset holdings over time as a basis for financial statements.",
    },
    {
        color: "#F76527",
        title: "Acquistion costs calculator*",
        tooltip: "Automated calculation of asset acquisition costs based on transactions.",
    },
    {
        color: "#F76527",
        title: "Financial statement",
        tooltip: "Create monthly and annual statements that you can hand over to your tax advisor or accountant for further processing.",
    },
    {
        color: "#F76527",
        title: "User action logging",
        tooltip: "When working in a team and with the tax consultant, it is important that user activities are recorded in order to document changes.",
    },
    {
        color: "#F76527",
        title: "Open banking support",
        tooltip: "We offer appropriate interfaces to automate the recording of bank transactions and account balances.",
    },
    {
        color: "#F76527",
        title: "Accounting data export",
        tooltip: "Different data such as asset lists, asset inventory, financial statements and transaction lists can be exported if included in your subscription.",
    }
]

export const functionsReporting = [
    {
        color: "#F76527",
        title: "Portfolio inventory report",
        tooltip: "A suitable report for every requirement. Contact our Sales Team for more information.",
    },
    {
        color: "#686F87",
        title: "Client performance report",
        tooltip: "A suitable importer for every requirement. Contact our Sales Team for more information.",
    },
    {
        color: "#686F87",
        title: "Transaction graph viewer",
        tooltip: "Easily share information about the origin of your assets with your bank or others.",
    }
]

export const payPerUse = [
    {
        color: "#1AB200",
        title: "Client management",
        tooltip: "Manage your clients and their shares within our tool for evaluations at client level.",
    },
    {
        color: "#1AB200",
        title: "Fund shares management",
        tooltip: "Activate additional seats for team members if needed. Downgrade on monthly base possible.",
    },
    {
        color: "#1AB200",
        title: "Customizable commission calculator",
        tooltip: "We store your management and performance fee model and enable automated calculation of the commission.",
    },
    {
        color: "#1AB200",
        title: "Customizable share price calculator",
        tooltip: "We recalculate the share price on a cyclical basis, taking into account the commission incurred. ",
    },
    {
        color: "#1AB200",
        title: "Public portfolio viewer",
        tooltip: "Share portfolio insides with your clients.",
    }
]

export const cardsData = {
    free: {
        coreFunctions: [
            { index: '10' },
            { index: '2' },
            { index: '-' },
            { index: 'check' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: 'Help-Center'},
            { index: '-' },
        ],
        importOptions: [
            { index: '120+ smart Integrations' },
            { index: 'check' },
            { index: '-' },
            { index: 'daily' },
            { index: '-' },
            { index: '-' },
        ],
        assetOptions: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsTracking: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: '1 month' },
            { index: 'last 7 days' },
            { index: 'check' },
            { index: '-' },
        ],
        functionsAccounting: [
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' }
        ],
        functionsReporting: [
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
        ],
        payPerUse: [
            { index: '-' },
            { index: '-' },
            { index: '-' }
        ]
    },
    adopter: {
        coreFunctions: [
            { index: 'unlimited' },
            { index: 'unlimited' },
            { index: '50 000' },
            { index: 'check' },
            { index: '-' },
            { index: 'check' },
            { index: 'check' },
            { index: 'E-Mail, In-App Chat'},
            { index: 'check' },
        ],
        importOptions: [
            { index: '120+ smart Integrations' },
            { index: 'check' },
            { index: 'check' },
            { index: 'hourly' },
            { index: 'daily' },
            { index: 'check' },
        ],
        assetOptions: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsTracking: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'unlimited' },
            { index: '16 month' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsAccounting: [
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
        ],
        functionsReporting: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' }
        ],
        payPerUse: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ]
    },
    starter: {
        coreFunctions: [
            { index: 'unlimited' },
            { index: 'unlimited' },
            { index: '50 000' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: '-' },
            { index: 'E-Mail, In-App Chat'},
            { index: '-' },
        ],
        importOptions: [
            { index: '120+ smart Integrations' },
            { index: 'check' },
            { index: '-' },
            { index: 'hourly' },
            { index: 'daily' },
            { index: 'check' },
        ],
        assetOptions: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsTracking: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'unlimited' },
            { index: '16 month' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsAccounting: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' }
        ],
        functionsReporting: [
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' },
            { index: '-' }
        ],
        payPerUse: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ]
    },
    advanced: {
        coreFunctions: [
            { index: 'unlimited' },
            { index: 'unlimited' },
            { index: '150 000 (Optional up to 500.000+)' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'In-App Chat, Key Account Manager'},
            { index: 'check' },
        ],
        importOptions: [
            { index: '120+ smart Integrations' },
            { index: 'check' },
            { index: 'check' },
            { index: 'hourly' },
            { index: 'daily' },
            { index: 'check' },
        ],
        assetOptions: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsTracking: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'unlimited' },
            { index: 'unlimited' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsAccounting: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ],
        functionsReporting: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ],
        payPerUse: [
            { index: 'check' },
            { index: 'check' },
            { index: 'check' },
        ]
    },

}


export const boardMembers = [
    {
        name: "Florian Wimmer",
        job: "CEO - Blockpit AG",
        position: "Advisor Crypto Tax & Crypto Space",
        image: "/DEMO/images/advisory-1.jpg",
        linkedin: "https://www.linkedin.com/in/florian-wimmer",
        twitter: "https://twitter.com/FlorianWimmerAT%20%20"
    },
    {
        name: "Peter Augustin",
        job: "Founder - Tigris Web3 Fund",
        position: "Advisor Crypto Funds & Web3",
        image: "/DEMO/images/advisory-2.jpg",
        linkedin: "https://www.linkedin.com/in/peter-augustin-293475186/"
    },
    {
        name: "Martin Roy",
        job: "Mergers & Acquisitions",
        position: "Global Strategy",
        image: "/DEMO/images/advisory-3.jpg",
        linkedin: "https://www.linkedin.com/in/martin-roy-31711b1/"
    },
    {
        name: "Franz Großmann",
        job: "IT Infrastructure & Security Expert",
        position: "Cyber Security",
        image: "/DEMO/images/advisory-4.jpg",
        linkedin: "https://www.linkedin.com/in/franz-grossmann-schoellernetworkcontrol/"
    }
];

export const TestimonialsArray = [
    {
        name: "Andre",
        quote: "3Folio offers me an overview I have been dreaming for a long time",
        link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        avatarUrl: "https://images.unsplash.com/flagged/photo-1553642618-de0381320ff3?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8dGF4aXx8fHx8fDE2NzMxMDEzMjI&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
        position: "Co-founder of Becao."
    },
    {
        name: "Andre",
        quote: "3Folio offers me an overview I have been dreaming for a long time",
        link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        avatarUrl: "https://images.unsplash.com/flagged/photo-1553642618-de0381320ff3?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8dGF4aXx8fHx8fDE2NzMxMDEzMjI&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
        position: "Co-founder of Becao."
    },
    {
        name: "Anne",
        quote: "Great crypto fund manager. Great support.",
        link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        // get unsplash image with 75x75px
        avatarUrl: "https://images.unsplash.com/photo-1481214110143-ed630356e1bb?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8dGF4aXx8fHx8fDE2NzMxMDEzMjI&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
        position: "Co-founder of Becao."
    },
    {
        name: "Anton",
        quote: "Perfect product with excellent technical implementation.",
        link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        avatarUrl: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
        position: "iCo-founder of Becao."
    },
]