import { Link } from "react-router-dom";
import Button from "./Button";
import { motion, useScroll } from "framer-motion"
import { useEffect, useState } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import ReactGA from "react-ga";
import {LangSelector} from "./lang-selector/LangSelector";
import { T } from "@tolgee/react";
import {WEBSITE_SHOW_PRICE_LIST} from "../utils";

function Header() {
    const { trackEvent } = useMatomo();

    const links = [
        {
            href: '/', 
            label: 'home',
            dropdown: [
                // { href: '/#roadmap', label: 'Roadmap' },
                // { href: '/#partners', label: 'Partners' },
                // { href: '/#faq', label: 'FAQ' },
            ]
        },
        // {
        //     href: '/',
        //     label: 'Products',
        //     dropdown: [
        //         { href: '/#', label: 'Product A' },
        //         { href: '/#', label: 'Product B' },
        //         { href: '/#', label: 'Product C' },
        //     ]
        // },
        { href: '/#features', label: 'features' },
        { href: '/#guideline', label: 'guideline' },
        { href: '/#roadmap', label: 'roadmap' },
        { href: '/#faq', label: 'faq' },
        // { href: '/blog', label: 'Blog' },
        { href: '/pricing', label: 'price_list' },
        { href: '/integrations', label: 'integrations_status' },
        // { href: 'https://3folio.notion.site/Job-Board-d0e0abd8fc71457987258db788ca6923', label: 'Jobs' }
    ];

    const { scrollY } = useScroll();
    const [ fixedHeader, setFixedHeader] = useState(true);
    const [ mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [ openDropdownMenuId, setOpenDopdownMenuId] = useState(-1); // -1 = none

    const openCalendlyModal = () => {
        const event = new CustomEvent('openCalendlyModal');
        window.dispatchEvent(event);
      };

    const toggleDropdownMenu = (id: number) => {
        if(openDropdownMenuId === id) {
            setOpenDopdownMenuId(-1);
        } else {
            setOpenDopdownMenuId(id);
        }
    }
    

    useEffect(() => {
        // scrollY.onChange((latest) => {
        //     setFixedHeader(latest > 0);
        // })

        if(mobileMenuOpen && !fixedHeader) {
            setFixedHeader(true);
        }

        // if(fixedHeader && !mobileMenuOpen && scrollY.get() === 0) {
        //     setFixedHeader(false);
        // }
    }, [scrollY, mobileMenuOpen, fixedHeader]);

    useEffect(() => {
        if(mobileMenuOpen && !fixedHeader && scrollY.get() > 0) {
            setFixedHeader(true);
        }
    }, [mobileMenuOpen, fixedHeader, scrollY]);

    const handleResize = () => {
        if(window.innerWidth > 768) {
            setMobileMenuOpen(false);
        }
    }
    window.addEventListener('resize', handleResize)

    const headerBackgroundAnimation = {
        transparent: {
            backgroundColor: "#ffffff00",
        },
        white: {
          backgroundColor: "#ffffff"
        }
      }

    const onLinkClicked = (href: string) => {
        mobileMenuOpen && setMobileMenuOpen(false);
        const position = document.getElementById(href.replace('/#', ''))?.offsetTop;
        if(position) {
            window.scrollTo({ top: position, behavior: 'smooth' });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const goToSignUp = () => {
        ReactGA.event({
            category: 'hero section',
            action: `go-to-app-clicked`,
            label: "Go to App Click",
            nonInteraction: true
        });
        window.open('https://app.eu.3folio.finance/register', '_blank');
    }

    const goToApp = () => {
        ReactGA.event({
            category: 'hero section',
            action: `go-to-app-clicked`,
            label: "Go to App Click",
            nonInteraction: true
        });
        window.open('https://app.eu.3folio.finance/', '_blank');
    }

    const onButtonClick = (type: 'features' | 'book-call') => {
        trackEvent({ category: 'header', action: `${type} button clicked` });

        if(type === 'book-call') {
            openCalendlyModal();
        }
    }


    return <motion.header key={Math.random()} className={"py-4 px-6 flex sticky top-0 left-0 right-0 z-50 " + (fixedHeader ? "shadow-sm" : "") + (mobileMenuOpen ? " h-full " : "")}
        variants={headerBackgroundAnimation}
        initial="white"
        animate={fixedHeader || mobileMenuOpen ? "white" : "white"}
    >
        <nav className=" mx-auto flex flex-row flex-nowrap w-screen justify-between">
            <ul key={'home'} className="pl-4 m-0 max-[200px]:hidden flex items-center">
                <li key={'one'}>
                <Link key={'linkTo'} to="/" onClick={() => onLinkClicked("/")}>
                {
                    fixedHeader ? <img src="/logo.png" alt="logo" className="h-14 -mt-1"/> : <img src="/logo-white.png" alt="logo" className="h-14 -mt-1"/>
                }
                </Link>
                </li>
            </ul>
            <ul key={'nav'} className="flex m-0 flex-row flex-nowrap items-center justify-center max-md:hidden">
            {
            links.map((link, i) =>

                <>
                    {link.href.includes('http') ? (
                        <li key={link.label} className={`p-2 text-[0.95rem] text-${fixedHeader ? 'slate-900' : 'white'}`} onClick={() => onLinkClicked(link.href)}>
                            <a href={link.href} target="_blank" rel="noreferrer">
                                <T keyName={link.label} />
                            </a>
                        </li>
                    ) : ( <>
                        { link.href !== '/pricing'  ?
                                <li key={link.label} className={`py-2 px-4 text-[0.95rem] font-bold text-${fixedHeader ? 'slate-900' : 'white'}`} onClick={() => onLinkClicked(link.href)}>
                                    <Link key={`link-to-${link.label}`} to={link.href}>
                                        <T keyName={link.label} />
                                    </Link>
                                </li> :
                             WEBSITE_SHOW_PRICE_LIST && <li key={link.label} className={`py-2 px-4 text-[0.95rem] font-bold text-${fixedHeader ? 'slate-900' : 'white'}`} onClick={() => onLinkClicked(link.href)}>
                                <Link key={`link-to-${link.label}`} to={link.href}>
                                    <T keyName={link.label} />
                                </Link>
                            </li>
                        }

                        </>
                    )}
                </>
            )
            }

            </ul>
            <ul key={'goApp'} className="max-md:hidden m-0 text-right items-center flex flex-row flex-nowrap">
                <li key={'app_login'}>
                    <Button key={'btn-app-one'}  text="Log In" color="secondary" size="medium" className="ml-1 py-3 max-[886px]:px-3 border-2" onClick={() => goToApp()}/>
                </li>
                <li key={'app_go_to'}>
                    <Button key={'btn-app-one'} text={<T keyName="register_whitelist" />} color="primary" size="medium" className="ml-1 py-3 max-[886px]:px-3" onClick={() => goToSignUp()}/>
                </li>
                <li key={'lang'} className={'flex max-w-[80px]'}>
                    <LangSelector />
                </li>
            </ul>
            <ul key={'mobile_nav'} className="md:hidden">
                <li key={'mobile_nav_one'}>
                    <Button key={'btn-mobile-nav'} text="Menu" icon={mobileMenuOpen ? "long-arrow-right-up" : "menu"} color={fixedHeader ? 'secondary' : 'outline'} size="large" onClick={mobileMenuOpen === true ? () => setMobileMenuOpen(false) : () => setMobileMenuOpen(true)}/>
                </li>
            </ul>
        </nav>

        {/* Mobile menu */}
        { mobileMenuOpen &&
                <div className={mobileMenuOpen ? "bg-white h-auto !md-hidden block absolute left-0 top-0 mt-[100px] w-full py-3 -mt-2" : "hidden" }>
                    <button onClick={() => setMobileMenuOpen(false)}
                    className={'flex absolute top-6 right-6'}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <ul key={'mobile_nmenu'} className={'text-center'}>
                    {links.map((link, index) =>
                        <>{
                    link.href.includes('http') ? (

                            <li key={`menu-${index}`} className={`p-2 text-[0.95rem] text-${fixedHeader ? 'slate-900' : 'white'}`} onClick={() => onLinkClicked(link.href)}>
                                <a href={link.href} target="_blank" rel="noreferrer">
                                    <T keyName={link.label} />
                                </a>
                            </li>
                            ) : (
                        link.href !== '/pricing' ? <li key={`route-menu-${index}`} className={`p-2 text-${fixedHeader ? 'slate-900' : 'white'}`} onClick={() => onLinkClicked(link.href)}>
                            <Link key={`link-to-mob=${link.label}`} to={link.href}>
                                <T keyName={link.label} />
                            </Link>
                        </li> :
                        WEBSITE_SHOW_PRICE_LIST &&
                                <li key={`route-menu-${index}`} className={`p-2 text-${fixedHeader ? 'slate-900' : 'white'}`} onClick={() => onLinkClicked(link.href)}>
                                    <Link key={`link-to-mob=${link.label}`} to={link.href}>
                                        <T keyName={link.label} />
                                    </Link>
                                </li>
                        )}
                            </>
                        )}
                    </ul>
                    <ul key={'menu-app'} className="text-center">
                        <li key={'mobile-go-to-aoo'}>
                            <Button key={'btn-mobile-to-app'} text="Login"   color="primary" size="medium" className="mt-2 block max-md:mx-auto w-4/12 min-w-[200px]" onClick={() => goToApp()}/>
                        </li>
                        <li key={'mobile-app_go_to'}>
                            <Button key={'btn-app-one'} text={<T keyName="register_whitelist" />} color="primary" size="small" className="ml-1 py-3 max-[886px]:px-3" onClick={() => goToSignUp()}/>
                        </li>
                        <li key={'lang'} className={'flex text-center justify-center pl-12'}>
                            <LangSelector />
                        </li>
                    </ul>
                </div>
            }
    </motion.header>;
}

export default Header;