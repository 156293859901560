import { T } from "@tolgee/react";

function Partners() {
    const logos = ["DEMO/logos/blockpit.png", "DEMO/logos/validvent-tax.svg"];

    return(
        <div className="my-16">
            <p className="text-[#98A2B3] font-semibold text-[24px]">
                <T keyName={'strong_partnerships'} />
            </p>
            <div className="flex flex-row flex-wrap justify-center">
                {logos.map((logo, i) => (
                    <img src={logo} alt="logo" height="45px" className="m-4 p-1 h-[45px] object-contain " key={"pressLogo-" + i} />
                ))}
            </div>
        </div>
    )
}

export default Partners;