import './App.css';

// React 
import React, { useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// Toastify
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// Components 

import Footer from "./components/Footer";

// Sections
import HeroSection from './sections/home/Hero';
import PressLogos from './sections/home/PressLogos';
import ProblemCards from './sections/home/ProblemCards';
import FeaturesIllustrated from './sections/home/FeaturesIllustrated';
import FeaturesDetailed from './sections/home/FeaturesDetailed';

import Partners from './sections/home/Partners';
import InvestorsBox from './sections/home/InvestorsBox';
import FAQ from './sections/home/FAQ';

import Contact from './sections/home/Contact';
import AnnouncementBanner from "./sections/home/AnnouncementBanner";

import ImprintContent from "./sections/legal/ImprintContent";

// Matomo
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import CalendlyModalComponent from './components/CalendlyModal';
import ProductContent from './sections/product/ProductContent';

import ReactGA from 'react-ga';
import {PriceList} from "./sections/price-list/PriceList";
import {Legal} from "./sections/legal/Legal";
import {Testimonials} from "./sections/testimonials/Testimonials";
import Header from "./components/Header";
import {WEBSITE_SHOW_PRICE_LIST, WEBSITE_SHOW_TESTIMONIALS} from "./utils";
import {TermsOfUse} from "./sections/legal/TermsOfUse";
import {TermsAndConditions} from "./sections/legal/TermsAndConditions";
import {PrivacyPolicy} from "./sections/legal/PrivacyPolicy";
import {PrivacyContent} from "./sections/legal/PrivacyContent";
import {CookiePolicy} from "./sections/legal/CookiePolicy";
import {ErrorPage} from "./sections/error/ErrorPage";
import {AdvisoryBoard} from "./sections/home/AdvisoryBoard";
import {SupportedIntegrations} from "./sections/supported-integrations/SupportedIntegrations";



function App() {

    ReactGA.initialize('G-99CW0PVGV0');
    ReactGA.pageview(window.location.pathname + window.location.search);
    // const [data, setData] = useState<any>([])
    useEffect(() => {

        // fetch(`https://strapi-3folio.onrender.com/api/blogs?populate=*`)
        //     .then((response) => response.json())
        //     .then((actualData) => {
        //         setData(actualData.data)
        //     });

    }, [])




    return (
    <>
      <Router>
      <CalendlyModalComponent key={Math.random()}/>
        <Routes>
          <Route key={'home'} path='/' element={<Home />} />
            {/*<Route key={'blog'} path='/blog' element={<BlogOverview data={data}/>} />*/}
            {WEBSITE_SHOW_PRICE_LIST && <Route key={'pricing'} path='/pricing' element={<PriceList />} /> }
          {/*<Route key={'blog-slug'} path='/blog/:id' element={<BlogPage singleBlog={data} />} />*/}
          {/*<Route path='/product' element={<Product/>} />*/}
          {/*<Route key={'privacy'} path='/privacy' element={<Privacy/>} />*/}
            <Route key={'imprint'} path='/imprint' element={<Imprint/>} />
            <Route key={'legal'} path='/legal' element={<TermsOfUse />} />
            <Route key={'terms-of-use'} path='/legal/terms-of-use' element={<TermsOfUse />} />
            <Route key={'terms-and-conditions'} path='/legal/terms-and-conditions' element={<TermsAndConditions />} />
            <Route key={'terms-and-conditions-user'} path='/legal/terms-and-conditions/user' element={<TermsAndConditions />} />
            <Route key={'terms-and-conditions-user'} path='/legal/terms-and-conditions/company' element={<TermsAndConditions />} />
            <Route key={'privacy-policy'} path='/legal/privacy-policy' element={<PrivacyPolicy />} />
            <Route key={'cookie-policy'} path='/legal/cookie-policy' element={<CookiePolicy />} />
            <Route key={'legal'} path='/legal' element={<Legal />} />
            <Route key={'integrations'} path='/integrations' element={<SupportedIntegrations />} />
          <Route key={'404'} path={'*'} element={<ErrorPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}


function Home() {

  const { trackPageView, trackEvent } = useMatomo()

    ReactGA.initialize('G-99CW0PVGV0');


  // Track page view
  useEffect(() => {
    trackPageView()
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [trackPageView])


  // Move to page section when hash is present in URL
  let location = useLocation();

  useEffect(() => {
    if(location.hash) {
      trackEvent({ category: location.hash, action: 'url-change' });
        ReactGA.event({
            category: location.hash,
            action: 'url-change',
            label: 'Url Change',
            nonInteraction: true
        });
      const position = document.getElementById(location.hash.replace('#', ''))?.offsetTop;
      if(position) {
        window.scrollTo({ top: position - 120, behavior: 'smooth' });
      }
    } else {
      trackEvent({ category: '#', action: 'url-change' });
        ReactGA.ga({
            category: '#',
            action: `url-change`
        });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.hash, trackEvent]);

  const announcement = {
    msg: "book_demo",
    link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
};


    const onButtonClick = (type: 'features' | 'book-call') => {
        trackEvent({ category: 'header', action: `${type} button clicked` });
        ReactGA.event({
            category: 'header',
            action: `${type} button clicked`,
            label: 'Header Book Call Clicked',
            nonInteraction: true
        });

        if(type === 'book-call') {
            openCalendlyModal();
        }
    }

    const openCalendlyModal = () => {
        const event = new CustomEvent('openCalendlyModal');
        window.dispatchEvent(event);
    };

    const ga = ReactGA.ga();
    const eventTrack = (category:any, action:any, label:any) => {
        console.log("GA event:", category, ":", action, ":", label);
        // ReactGA.event({
        //     category: category,
        //     action: action,
        //     label: label,
        //     nonInteraction: true
        // })
        ga('send', 'event', category, action, label)
    }


    return (
        <div className="App">

            <Header key={'header'}/>
            <AnnouncementBanner key={'ann-banner'} onClick={() => {
                eventTrack("Book Call", "Book call Button", "Button")
                onButtonClick('book-call')
            }} message={announcement.msg} link={announcement.link}/>
            <HeroSection key={'hero'}/>
            {WEBSITE_SHOW_TESTIMONIALS && <Testimonials/>}
            <PressLogos key={'press'}/>
            <ProblemCards key={'problem-cards'}/>
            <FeaturesIllustrated key={'FeaturesIllustrated'}/>
            <FeaturesDetailed key={'FeaturesDetailed'}/>
            {/*<Testimonials />*/}
            <AdvisoryBoard key={'AdvisoryBoard'}/>
            <Partners key={'Partners'}/>
            <FAQ key={'FAQ'}/>
            <InvestorsBox key={'InvestorsBox'}/>
            {/*<Quote key={'Quote'} />*/}
            <Contact key={'Contact'}/>
            <Footer key={'Footer'}/>
            <ToastContainer key={'ToastContainer'}/>
        </div>
    );


}

function Privacy() {
  const { trackPageView } = useMatomo()

  // Track page view
  useEffect(() => {
    trackPageView()
  }, [trackPageView])

  return (
    <div className="App">
      <Header />
        <PrivacyContent />
      <Footer />
    </div>
  )
}

function Imprint() {
  const { trackPageView } = useMatomo()

  // Track page view
  useEffect(() => {
    trackPageView()
  }, [trackPageView])

  return (
    <div className="App">
      <Header />
        <ImprintContent />
      <Footer />
    </div>
  )
}

function Product() {
  const { trackPageView } = useMatomo()

  // Track page view
  useEffect(() => {
    trackPageView()
  }, [trackPageView])

  return (
    <div className="App">
      <Header />
        <ProductContent />
      <Footer />
    </div>
  )
}

export default App;
