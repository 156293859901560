import React from "react";

export const HelpIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 16 17" fill="none" className={'pl-1'}>
            <path d="M8 2.5C4.69062 2.5 2 5.19062 2 8.5C2 11.8094 4.69062 14.5 8 14.5C11.3094 14.5 14 11.8094 14 8.5C14 5.19062 11.3094 2.5 8 2.5ZM8 13.5C5.24375 13.5 3 11.2563 3 8.5C3 5.74375 5.24375 3.5 8 3.5C10.7563 3.5 13 5.74375 13 8.5C13 11.2563 10.7563 13.5 8 13.5Z" fill="#83899F"/>
            <path d="M9.01568 5.18126C8.76568 5.06251 8.39381 4.99688 8.00006 5.00001C7.43443 4.99063 6.89068 5.18751 6.47506 5.55938C6.09693 5.89688 5.85631 6.34063 5.81256 6.77813C5.78756 7.01876 5.96256 7.23438 6.20318 7.25626C6.44381 7.28126 6.65943 7.10626 6.68131 6.86563C6.72193 6.45001 7.21256 5.85938 7.98756 5.87501H8.00006C8.29068 5.87188 8.52818 5.92188 8.63443 5.97188C9.31256 6.29063 9.31256 6.71876 9.31256 6.85938C9.31256 7.425 8.97193 7.68125 8.36881 8.08438C7.72193 8.51875 7.40631 9.06563 7.40631 9.75C7.40631 9.99063 7.60318 10.1875 7.84381 10.1875C8.08443 10.1875 8.28131 9.99063 8.28131 9.75C8.28131 9.4625 8.35006 9.15 8.85631 8.8125C9.45006 8.41563 10.1876 7.92188 10.1876 6.86251C10.1876 6.13751 9.77193 5.54063 9.01568 5.18126Z" fill="#83899F"/>
            <path d="M7.8125 12C8.15768 12 8.4375 11.7202 8.4375 11.375C8.4375 11.0298 8.15768 10.75 7.8125 10.75C7.46732 10.75 7.1875 11.0298 7.1875 11.375C7.1875 11.7202 7.46732 12 7.8125 12Z" fill="#83899F"/>
        </svg>
    )
}