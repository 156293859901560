import React from "react";
import {CheckIconCircle} from "../../components/icons/CheckIconCircle";

export const EnterpriceSuiteList = () => {

    return (
        <div className={'flex justify-start flex-row justify-between'}>
            <div className={'flex flex-col'}>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Everthing from Copilot Pro</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Unlimited Workspaces</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Client Management</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Commission Calculator</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Share price Calculator</span>
                </div>
            </div>
            <div className={'flex flex-col'}>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Key Account Manager</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Branded Reports</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Private API Endpoints</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Enterprise Updates</span>
                </div>
                <div className={'flex justify-start'}>
                    <div className={'flex'}>
                        <CheckIconCircle />
                    </div>
                    <span className={'justify-start text-[12px] text-[#83899F] text-start pl-1'}>Dedicated Infrastructure(Optional)</span>
                </div>
            </div>
        </div>
    )
}